<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <div style="display:flex;justify-content:space-between;align-items:flex-start">
        <el-button class="ele-btn-icon addbtn " @click="dialogFormVisibleAddActivity=true"
                   v-if="permission.includes('sys:user_charge:add')">添加活动
        </el-button>
        <el-form :model="table.where" class="ele-form-search" style="display:flex;flex-wrap: wrap;"
                 @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
          <el-form-item label="省:" label-width="35px" class="w-170">
            <el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)"
                       placeholder='请选择省' clearable>
              <el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
                         :label="option.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="市:" label-width="35px" class="w-150">
            <el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid),$forceUpdate()"
                       placeholder='请选择市' clearable>
              <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
                         :label="option.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="县/区:" label-width="62px" class="w-200">
            <el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
              <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
                         :label="option.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
                       class="ele-btn-icon search-margin-left">搜索
            </el-button>
            <el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" :stripe=true
                      highlight-current-row>
        <template slot-scope="{index}">
          <el-table-column type="selection" width="45" align="center" fixed="left"/>
          <el-table-column type="index" :index="index" label="序号" width="60" align="center"
                           show-overflow-tooltip/>
          <el-table-column prop="admin_real_name" label="管理员名称" show-overflow-tooltip min-width="100"/>
          <el-table-column prop="set_area" label="地区" show-overflow-tooltip min-width="220"/>
          <el-table-column prop="zt" label="状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.type == 1 ? '启用' : (scope.row.type == 2 ? '已关闭' : (scope.row.type == 3 ? '已暂停' : (scope.row.type == 4 ? '待开启' : ''))) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="price" label="奖励金额(元)" show-overflow-tooltip/>
          <el-table-column prop="zprice" label="总金额(元)" show-overflow-tooltip/>
          <el-table-column prop="sprice" label="剩余金额(元)" show-overflow-tooltip/>
          <el-table-column prop="return_price" label="活动剩余金额退还(元)" show-overflow-tooltip/>
          <el-table-column prop="remind_amount" label="过期零钱(元)" show-overflow-tooltip/>
          <el-table-column prop="num" label="领取人数" show-overflow-tooltip/>
          <el-table-column prop="create_time" label="创建日期" show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">{{ row.create_time * 1000 | toDateString }}</template>
          </el-table-column>
          <el-table-column prop="start_time" label="开始日期" show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">{{ row.start_time * 1000 | toDateString }}</template>
          </el-table-column>
          <el-table-column prop="end_time" label="结束日期" show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">{{ row.end_time * 1000 | toDateString }}</template>
          </el-table-column>
          <el-table-column prop="ex_time" label="有效期(天)" min-width="120"/>
          <el-table-column label="操作" width="240px" align="center" fixed="right">
            <template slot-scope="{row}">
              <el-link icon="el-icon-document" type="primary" :underline="false"
                       @click="handleRecord(row)" v-if="permission.includes('sys:user_charge:lingqujilu')">领取记录
              </el-link>
              <!-- <el-link slot="reference" @click="switch_start(row)" icon="el-icon-error" :type="row.is_switch==1?'danger':'primary'" :underline="false"
                                   v-if="permission.includes('sys:face_config:start')  && row.type!=2">
                              关闭
                          </el-link> -->
<!--            1启用2关闭3暂停4待开启-->

              <el-link slot="reference" @click="pause_start(row,1)" icon="el-icon-success" 
								type="primary" :underline="false" v-if="row.type == 3 || row.type == 4 && permission.includes('sys:user_charge:pause_start')">
                启用
              </el-link>
              <el-link slot="reference" @click="pause_start(row,3)" icon="el-icon-error" 
								type="danger" :underline="false" v-if="row.type == 1 && permission.includes('sys:user_charge:pause_close')">
                暂停
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
      <!-- 对话框：添加 -->
      <el-dialog v-dialogDrag title="添加送零钱" :visible.sync="dialogFormVisibleAddActivity" width="1400px" @closed="editShow={}" :destroy-on-close="true" :lock-scroll="false">
        <div style="display: flex;justify-content: space-between;background: white;">
					<el-card shadow="never">
						<el-form :model="editShow" ref="editShow" label-width="120px" style="width:400px" :rules="editRules">
							<el-form-item label="所属区域:" prop="pid">
								<el-select v-model="editShow.pid" placeholder="请选择省" class="selectStyle mr-10 mb-20"
									@change="handleChangeProv1(editShow.pid)" clearable>
									<el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
								</el-select>
								<el-select v-model="editShow.cid" placeholder="请选择市" class="selectStyle mr-10 mb-20"
									@change="handleChangeCity1(editShow.cid)" clearable>
									<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name"></el-option>
								</el-select>
								<el-select v-model="editShow.aid" placeholder="请选择县/区" class="selectStyle mr-10"
									@change="$forceUpdate()" clearable>
									<el-option v-for="option in districtArr" :value="option.aid" :key="option.aid" :label="option.name"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="所属APP：" :rules="{required: true,message: '请选择所属APP',trigger: 'change'}" style="height: 0px;overflow: hidden;margin-bottom: 0;">
								<el-select v-model="editForm.atype" placeholder="请选择" class="input216" clearable>
									<el-option v-for="option in atypeArr" :value="option.type" :key="option.type" :label="option.name"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="总金额(元):" prop="zprice">
								<el-input v-model="editShow.zprice" autocomplete="off" class="input216"></el-input> 元
							</el-form-item>
							<el-form-item label="零钱金额(元):" prop="price">
								<el-input v-model="editShow.price" autocomplete="off" class="input216"></el-input> 元
							</el-form-item>
							<el-form-item label="开始时间:" prop="start_time">
								<el-date-picker class="input216" v-model="editShow.start_time" type="datetime"
									value-format=" yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
								</el-date-picker>
							</el-form-item>
							<el-form-item label="结束时间:" prop="end_time">
								<el-date-picker class="input216" v-model="editShow.end_time" type="datetime"
									value-format=" yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
								</el-date-picker>
							</el-form-item>
							<el-form-item label="零钱有效期:" prop="ex_time">
								<el-input v-model="editShow.ex_time" placeholder="单位（天）" autocomplete="off" style="width:220px"></el-input> 天
							</el-form-item>
						</el-form>
					</el-card>
					<div style="border-left: solid 1px #C6C6C6;padding-left: 40px;">
						<div style="font-size: 18px;padding-bottom: 20px;">活动模板</div>
						<div style="color:rgb(51,204,153);font-size:14px;margin-bottom: 30px;">
							* 您好，活动模板内容仅供活动设置参考，可根据目前运营地区实际情况进行设置或直接采用模板内容
						</div>
						<div class="templateBox" style="padding: 0 0 20px 0;border-bottom: solid 1px #e6e6e6;">
							<div>
									<i class="el-icon-location" style="color:rgb(51,204,153);font-size:16px"></i>
									阶段一：(目的：{{template.purpose}})
							</div>
							<p style="margin-top: 14px;"></p>
							<p>添加红包优惠：{{template.red}}</p>
							<p>新用户送零钱：{{template.bonus}}</p>
							<p>佣金设置：{{template.commission}}</p>
							<p style="margin-top: 14px;"></p>
							<p style="color:rgb(51,204,153);">预计拓客数：{{template.num}}</p>
							<p style="color:rgb(51,204,153);">预计活动投入资金：{{template.price}}</p>
						</div>
						<div class="templateBox" style="padding: 20px 0 20px 0;border-bottom: solid 1px #e6e6e6;">
							<div>
									<i class="el-icon-location" style="color:rgb(51,204,153);font-size:16px"></i>
									阶段二：(目的：{{template.purpose1}})
							</div>
							<p style="margin-top: 14px;"></p>
							<p>添加红包优惠：{{template.red1}}</p>
							<p>新用户送零钱：{{template.bonus1}}</p>
							<p>佣金设置：{{template.commission1}}</p>
							<p style="margin-top: 14px;"></p>
							<p style="color:rgb(51,204,153);">预计拓客数：{{template.num1}}</p>
							<p style="color:rgb(51,204,153);">预计活动投入资金：{{template.price1}}</p>
						</div>
						<div class="templateBox" style="padding: 20px 0 20px 0;">
							<div>
									<i class="el-icon-location" style="color:rgb(51,204,153);font-size:16px"></i>
									阶段三：(目的：{{template.purpose2}})
							</div>
							<p style="margin-top: 14px;"></p>
							<p>添加红包优惠：{{template.red2}}</p>
							<p>新用户送零钱：{{template.bonus2}}</p>
							<p>佣金设置：{{template.commission2}}</p>
							<p style="margin-top: 14px;"></p>
							<p style="color:rgb(51,204,153);">预计拓客数：{{template.num2}}</p>
							<p style="color:rgb(51,204,153);">预计活动投入资金：{{template.price2}}</p>
						</div>
					</div>
				</div>
        <div slot="footer">
          <el-button @click="dialogFormVisibleAddActivity=false">取消</el-button>
          <el-button type="primary" @click="save">保存</el-button>
        </div>

      </el-dialog>

      <!-- 对话框：领取记录 -->
      <el-dialog v-dialogDrag title="新用户送零钱领取记录" :visible.sync="dialogVisibleRecord" width="1200px" :destroy-on-close="true" :lock-scroll="false">
        <el-card shadow="never">
          <!-- 搜索表单 -->
          <el-form class="ele-form-search" @keyup.enter.native="$refs.table1.reload()" @submit.native.prevent>
            <el-form-item class=" d-flex f-end">
              <el-button icon="el-icon-download" type="success" @click="exportExcel"
                         v-if="permission.includes('sys:order:export')">导出
              </el-button>
            </el-form-item>
            <!-- 数据表格 -->
            <ele-data-table :data="records" :choose.sync="choose" height="calc(100vh - 315px)" :stripe=true
                            highlight-current-row>
              <template slot-scope="{index}">
                <el-table-column type="selection" width="45" align="center" fixed="left"/>
                <el-table-column type="index" :index="index" label="序号" width="60" align="center"
                                 fixed="left" show-overflow-tooltip/>
                <el-table-column prop="address" label="地区" show-overflow-tooltip min-width="220"/>
                <el-table-column prop="name" label="用户名称" show-overflow-tooltip min-width="140"/>
                <el-table-column prop="phone" label="手机号" show-overflow-tooltip min-width="120"/>
                <el-table-column prop="price" label="领取金额(元)" show-overflow-tooltip min-width="120"/>
                <el-table-column prop="remind_price" label="剩余金额(元)" show-overflow-tooltip
                                 min-width="80"/>
                <el-table-column prop="ex_time" label="截至有效期" show-overflow-tooltip min-width="220"/>
                <el-table-column prop="use_status" label="使用状态" show-overflow-tooltip/>
              </template>
            </ele-data-table>
          </el-form>
        </el-card>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import {
  mapGetters
} from "vuex";

export default {
  name: "SysUser",
  data() {
    return {
      table: {
        url: '/finance/loose_list',
        where: {}
      },
      choose: [], // 表格选中数据
      editForm: {
        atype:2,
      }, // 表单数据
      checkForm: {},
      records: [],
      dialogTableVisibleCheck: false,
      dialogTableVisibleEdit: false,
      dialogTableVisibleUrgent: false,
      provArr: [],
      cityArr: [],
      districtArr: [],
      urgentList: [],
      imageUrl: false,
      headImg: '',
      dialogFormVisibleAddActivity: false,
      dialogVisibleRecord: false,
      editShow: {},
      atypeArr: [
      //   {
      //   type: 1,
      //   name: '咱县打车'
      // },
        {
          type: 2,
          name: '可蚁点'
        }
      ],
      row: [{
        state: '开启'
      },
        {
          state: '关闭'
        }
      ],
      editRules: { // 表单验证规则
        pid: [{
          required: true,
          message: '请选择省份',
          trigger: 'change'
        }],
        atype: [{
          required: true,
          message: '请选择所属APP',
          trigger: 'change'
        }],
        name: [{
          required: true,
          message: '请输入协议名称',
          trigger: 'blur'
        }],
        zprice: [{
          required: true,
          message: '请输入总金额',
          trigger: 'blur'
        },{
					pattern: /^([0-9][0-9]*)+(.[0-9]{1,2})?$/, 
					message: '请输入大于0的有效数字', 
					trigger: 'blur'
				}],
        price: [{
          required: true,
          message: '请输入零钱金额',
          trigger: 'blur'
        },{
					pattern: /^([0-9][0-9]*)+(.[0-9]{1,2})?$/, 
					message: '请输入大于0的有效数字', 
					trigger: 'blur'
				}],
        start_time: [{
          // type: 'date',
          required: true,
          message: '请选择开始时间',
          trigger: "change"
        }],
        end_time: [{
          // type: 'date',
          required: true,
          message: '请选择结束时间',
          trigger: "change"
        }],
        ex_time: [{
          required: true,
          message: '请输入基础配送距离',
          trigger: 'blur'
        }]
      },
			template:[]
    }
  },
  created() {
    this.$http.get('/common/province_list').then(res => {
      let data = JSON.parse(res.data)
      this.provArr = data
    })
		this.$http.get('/finance/activity_template').then(res=>{
			this.template = res.data.data
		})
  },

  computed: {
    ...mapGetters(["permission"]),
  },
  mounted() {
  },
  methods: {
    handleRecord(row) {
      this.dialogVisibleRecord = true
      this.$http.post('/finance/user_loose_list', {
        loose_id: row.id
      }).then(res => {
        this.records = res.data.data
        //console.log(this.records)
      })
    },
    save() {
      this.$refs['editShow'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          });
          this.$http.post('finance/loose_add', this.editShow).then(res => {
            loading.close()
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: res.data.msg
              });
              this.$refs.table.reload();
              this.dialogFormVisibleAddActivity = false
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          })
        } else {
          return false;
        }
      });
    },
    /**
     *选择省
     **/
    handleChangeProv(e) {
      /** 获取被选省份的pid**/
      var pid = ''
      this.provArr.forEach(function (item) {
        if (item.pid == e) {
          pid = item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list', {
        pid: pid
      }).then(res => {
        var data = JSON.parse(res.data)
        this.cityArr = data
        /** 选择省份清空市县**/
        this.table.where.cid = ''
        this.table.where.aid = ''


      })
    },
    /**
     *选择市
     **/
    handleChangeCity(e) {
			if(e){
      /** 获取被选市的cid**/
      var cid = ''
      this.cityArr.forEach(function (item) {
        if (item.cid == e) {
          cid = item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list', {
        cid: cid
      }).then(res => {
        var data = JSON.parse(res.data)
        this.districtArr = data
        /** 选择省份清空县**/
        this.table.where.aid = ''

      })
			}else{
				this.table.where.pid = ''
				this.table.where.aid = ''
			}
    },
    /**
     *选择省
     **/
    handleChangeProv1(e) {
			if(e){
      /** 获取被选省份的pid**/
      let pid = ''
      this.provArr.forEach(function (item) {
        if (item.pid == e) {
          pid = item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list', {
        pid: pid
      }).then(res => {
        let data = JSON.parse(res.data)
        this.cityArr = data
        /** 选择省份清空市县**/
        this.editShow.cid = ''
        this.editShow.aid = ''
      })
			}else{
				this.editShow.pid = ''
				this.editShow.aid = ''
			}
    },
    /**
     *选择市
     **/
    handleChangeCity1(e) {
				if(e){
      /** 获取被选市的cid**/
      let cid = ''
      this.cityArr.forEach(function (item) {
        if (item.cid == e) {
          cid = item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list', {
        cid: cid
      }).then(res => {
        let data = JSON.parse(res.data)
        this.districtArr = data
        /** 选择省份清空县**/
        this.editShow.aid = ''

      })
			}else{
				this.editForm.pid = ''
				this.editForm.aid = ''
			}
    },
    exportExcel() {
    },
    pause_start(row, Type) {
      if (Type == 3) {
        this.$confirm('确定暂停零钱活动吗?', '提示', {
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true
          });
          this.$http.post('/finance/set_loose_status', {
            loose_id: row.id,
            type: 3
          }).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: res.data.msg
              });
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        })
      }
      if (Type == 1) {
        this.$confirm('确定开启零钱活动吗?', '提示', {
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true
          });
          this.$http.post('/finance/set_loose_status', {
            loose_id: row.id,
            type: 1
          }).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: res.data.msg
              });
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        })
      }
    },
    switch_start(row) {
      this.$confirm('确定关闭零钱活动吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true
        });
        this.$http.post('/finance/set_loose_status', {
          loose_id: row.id,
          type: 2
        }).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: res.data.msg
            });
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      })
    }
  }
}
</script>

<style scoped>
.ele-block .el-upload-dragger {
  width: 100%;
}

.changeStyle {
  width: 150px;
  margin-right: 30px;
}

.ele-body {
  padding: 15px;
}

.el-upload-list el-upload-list--text {
  display: none !important
}

/deep/ .el-tabs__item {
  padding: 0 20px !important;
  font-size: 15px;
}

/deep/ .el-tabs__nav-scroll {
  background: white !important;
  padding-top: 10px;
}

.user-info-tabs >>> .el-tabs__nav-wrap {
  padding-left: 20px;
}
.templateBox p{
	padding: 4px 0 0 0;
}

/deep/ .el-dialog {
  width: 60%;
  margin-top: 80px !important;
}
</style>
